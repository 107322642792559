<template>
  <div>
    <b-row>
      <b-col
        v-for="(item,index) in dataList"
        :key="index"
        lg="4"
      >
        <b-card
          img-height="200px"
          :img-src="item.lessonPic"
        >
          <a>
            <b-card-text class="mb-2 ">
              <span
                class="title_"
                @click="detail(item.docID)"
              >
                {{ item.lessonName }}
              </span>
            </b-card-text>
          </a>
          <b-card-text
            class="mb-2"
            @click="show"
          >
            {{ item.lessonexplain }}
          </b-card-text>
          <div class="text-center">
            <b-row>
              <b-col lg="4">
                <b-button
                  variant="flat-primary"
                  tag="a"
                  class="btn-wishlist"
                >
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50"
                  />
                  <span>いいね</span>
                </b-button>
              </b-col>
              <b-col lg="8">
                <b-button
                  variant="flat-primary"
                  tag="a"
                  class="btn-cart"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  <small>お気に入りリストに入れる</small>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-card
      title="レッスン一覧"
    >
      <div
        v-for="(item,index) in dataList"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col md="8">
            <h5
              class="text-capitalize mb-75"
            >
              [ {{ index + 1 }} ]
              <span v-if="item.lessonName !== void 0">{{ item.lessonName }}</span>
            </h5>
          </b-col> -->
    <!-- submit and reset -->
    <!-- <b-col md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat-primary"
              @click="detail(item.docID)"
            >
              詳細
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card> -->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/lesson', query: { id } })
    },
    show() {
      window.console.log('🐶')
    },
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
</style>
