<template>
  <b-row class="match-height">
    <b-col lg="12">
      <ListLesson :data-list="lessonList" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ListLesson from '@/components/basic/lesson/ListLesson.vue'
import getAllDataList from '@/firestore/lesson/getAllLesson'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    ListLesson,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('lesson', ['lessonList']),
  },
  async mounted() {
    const data = {
      collection: 'lessonRegistration',
    }
    const response = await getAllDataList(data)
    if (response.status === 'success') {
      this.$store.dispatch('lesson/updateList', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
